<template>
    <AppPanel :location="['Relatorios Agendados']" subtitle="Relatorios Agendados">
        <template #content>
            <div class="">
                <AppPaginatedGrid
                    ref="grid"
                    :service="service"
                    :actionItems="actionItems"
                    newPath="/relatorios-agendados/new"
                    editPath="/relatorios-agendados/"
                    @onToggleMenu="onToggleMenu"
                    :loadingEffect="false"
                    :filtrosExtras="filtrosExtras"
                    subtitle="Relatórios Agendados"
                    tooltip="Listagem de relatórios em processamento"
                    nomeTelaDoManual="relatorios-agendados-list"
                >
                    <template #columns>
                        <Column :field="'clienteNome'" :header="'Clientes'">
                            <template #body="{ data }">
                                <div class="flex flex-column mr-2">
                                    {{ formatClienteNome(data.clienteNome) }}
                                </div>
                            </template>
                        </Column>
                        <Column :sortable="true" field="dataCriacao" sortField="dataCriacao" header="Data de Criação">
                            <template #body="{ data }">
                                <div class="flex flex-column mr-2">
                                    {{ $filters.formatDateOnly(data.dataCriacao) }}
                                </div>
                            </template>
                        </Column>
                        <Column :sortable="true" field="situacao" sortField="situacao" header="Situação"></Column>
                        <Column :sortable="true" field="dia" sortField="dia" header="Dia"></Column>
                        <Column :sortable="true" field="mesBase" sortField="mesBase" header="Mês Base">
                            <template #body="{ data }">
                                <div class="flex flex-column mr-2">
                                    {{ getMonthName(data.mesBase) }}
                                </div>
                            </template>
                        </Column>
                    </template>
                </AppPaginatedGrid>

                <AppInactivateDialog
                    :visible="showInactiveDialog"
                    v-if="showInactiveDialog"
                    customTitle="Tem certeza que deseja inativar o registro?"
                    :service="service"
                    :record="record"
                    @onConfirm="handleInactivateConfirm"
                    @onClose="showInactiveDialog = false"
                />
            </div>
        </template>
    </AppPanel>
</template>
<script>
import AppInactivateDialog from '@/components/AppInactivateDialog.vue';
import RelatoriosAgendadosService from '@/services/RelatoriosAgendadosService';
import { getCurrentCompany } from '@/services/store';

export default {
    components: {
        AppInactivateDialog
    },
    data() {
        return {
            autoload: false,
            service: null,
            record: {},
            empresas: [],
            showInactiveDialog: false,
            actionItems: [
                {
                    label: 'Editar',
                    icon: 'pi pi-pencil',
                    command: () => this.editRecord(this.record)
                },
                {
                    label: 'Inativar',
                    icon: 'pi pi-times-circle',
                    disabled: () => this.record.situacao === 'INATIVO',
                    command: () => this.showInactivateDialog(this.record)
                }
            ],
            filtrosExtras: {
                empresa: getCurrentCompany()
            }
        };
    },
    async created() {
        this.service = new RelatoriosAgendadosService('relatorios-agendados');
        setTimeout(() => {
            this.autoLoad();
        }, 3000);
    },
    watch: {
        autoload() {
            this.autoLoad();
        }
    },
    methods: {
        async autoLoad() {
            if (!this?.$refs?.grid) {
                return;
            }

            if (!this.autoload) {
                return;
            }

            await this.$refs.grid.load();

            setTimeout(() => {
                this?.autoLoad && this.autoLoad();
            }, 3000);
        },

        formatClienteNome(clienteNomeArray) {
            return clienteNomeArray.join(', ');
        },
        getMonthName(monthNumber) {
            const months = [
                'Janeiro',
                'Fevereiro',
                'Março',
                'Abril',
                'Maio',
                'Junho',
                'Julho',
                'Agosto',
                'Setembro',
                'Outubro',
                'Novembro',
                'Dezembro'
            ];
            return months[monthNumber - 1];
        },

        showInactivateDialog(record) {
            this.record = record;
            this.showInactiveDialog = true;
        },

        async handleInactivateConfirm() {
            this.showInactiveDialog = false;
            this?.$refs?.grid && this.$refs.grid.load();
        },

        onToggleMenu(event, data) {
            this.record = data;
        },

        editRecord(record) {
            this.$router.push('/relatorios-agendados/' + record._id);
        }
    }
};
</script>
